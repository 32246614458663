module.exports = {
  init: () => {
    console.log(`

                    ███████
                   █████████
                  ███████████
                 ███░░███░░███
                ███░░░░█░░░░░██
            █████░░░███████░░░████
           █░░░████░█░█░█░█░███░░░█
           █░░░███░░███░███░░██░░░█
         ███░░░███░░░░░░░░░░░██░░░███
         █████░░░░░░░░███░░░░░░░█████
       ██████░░░░░░░░░░░░░░░░░░░░██████
      ███████░░░░█████████████░░░███████
   ██████████░░░█░░░░░░░░░░░░░█░░████████
  ██████████████░░░░░░░░░░░░░░░██████████
 ███████████████░░░░░░░░░░░░░░░███████████
 █████████████████░░░░░░░░░░░░█████████████
 ██████████████████░░░░░░░░░░██████████████
 ██████████████████████████████████████████
 ██████████████░░░██████████░░░████████████
  █████████████░░░░░░████░░░░░░████████████
   █████████████░█░░░░██░░░░█░████████████
    █████████████░░░░░██░░░░░████████████
      ██████████░█░░░░██░░░░█░██████████
        ████████░█░░░░░░░░░░█░████████
       ████████░░░░░░█░░█░░░░░░████████
       ██████████████░░░░██████████████
      ███████████░░░░░░░░░░░░███████████
    █████████████░░░░█░█░░░░░████████████
   ███████████████░░█░█░█░░░██████████████
   ███████████████████████████████████████
   ███████████████████████████████████████
   ████████████████        ███████████████
    █████████████            ████████████
    ████████████              ███████████
 ████░░███░░░██               ██░░░███░░████
█░░░░░█░░░░██░█               █░██░░░░█░░░░░█
██████████████                 ██████████████

   $$\\   $$\\  $$$$$$\\  $$\\   $$\\  $$$$$$\\  
   $$ | $$  |$$  __$$\\ $$$\\  $$ |$$  __$$\\ 
   $$ |$$  / $$ /  $$ |$$$$\\ $$ |$$ /  \\__|
   $$$$$  /  $$ |  $$ |$$ $$\\$$ |$$ |$$$$\\ 
   $$  $$<   $$ |  $$ |$$ \\$$$$ |$$ |\\_$$ |
   $$ |\\$$\\  $$ |  $$ |$$ |\\$$$ |$$ |  $$ |
   $$ | \\$$\\  $$$$$$  |$$ | \\$$ |\\$$$$$$  |
   \\__|  \\__| \\______/ \\__|  \\__| \\______/
                                   v${process.env.VERSION}.${process.env.BUILD}
`)
  }
}