import $ from 'jquery'
import * as api from './kong.api'
import queryString from 'query-string'

let urlParams = queryString.parse(location.search)

// logo, back button
$(document).on('click', '.back', evt => {
    window.history.back()
})

$(document).on('click', '#logo', evt => {
    window.location = '/'
})

// header icons
$(document).on('click', '#icon-menu li', evt => {
    let data = $(evt.currentTarget).data()

    console.log(data)

    // JavascriptInterface in Android wrapper, to call from JS
    if (typeof Android != 'undefined') {
        Android.onIconMenuClick(JSON.stringify(data))
    }
})

$(document).on('click', '.survey', e => {
    // JavascriptInterface in Android wrapper, to call from JS
    if (typeof Android != 'undefined') {
        e.preventDefault()
        Android.onSurveyClick()
    }
})